import React from "react"
import LayoutTwo from "../components/layout/main/layoutTwo"


import PicContainer from "../components/picContainer/PicContainer"
import SEO from "../components/util/seo/Seo"

const TimelinePage = () => (
  <LayoutTwo title="Timeline" pageName="Timeline" description="Timeline Ilias Zales" type="kp" image="no">

    <SEO title="Timeline Ilia Zales" metaDescription='Timeline Ilias Zales' />


  </LayoutTwo>


)

export default TimelinePage
